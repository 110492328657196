<script>
import { orderCategories } from '@/misc.js';

export default {
  name: 'filter-bar',

  props: {
    keyword: {
      type: Object,
      required: true,
    },

    limit: {
      type: Number,
      default: +localStorage.getItem('imagesPerPage') || 20,
    },
  },

  data() {
    return {
      tags: [],
      imagesPerPage: +localStorage.getItem('imagesPerPage') || 20,
    };
  },

  computed: {

  },

  watch: {
    imagesPerPage(val) {
      localStorage.setItem('imagesPerPage', val);
      this.$emit('update:limit', val);
    },
  },

  methods: {
    hide() {
      this.$emit('onHide');
    },
  },
};
</script>

<template>
  <div class="filterbar h-full flex flex-col py-4 bg-gray-200 md:bg-gray-100 p-10">
    <div class="w-full text-left py-2 flex justify-between">
      <p>{{ $t('imagesPerPage') }}</p>
      <select v-model="imagesPerPage">
        <option value="20">20</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
    </div>

  </div>
</template>
