<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { translateLink } from '@/misc';
import Gallery from '@/components/gallery/Gallery.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import FilterBar from "./components/FilterBar";
import MobileFilterBar from "./components/MobileFilterBar";
import {i18n} from "vue-lang-router";


export default {
  name: 'keyword',

  data() {
    return {
      searchInitialized: false,
      keyword: null,
      error: null,
      pageInfo: null,
      limit: +localStorage.getItem('imagesPerPage') || 20,
      isCreating: false,
    };
  },

  async created() {
    // isCreating makes sure that some watchers wait until
    // the component is initialized before starting.
    this.isCreating = true;
    const keyword = this.$route.params.keyword;
    const page = this.$route.query.page || 1;
    this.limit = +localStorage.getItem('imagesPerPage') || 20;

    if (keyword) {
      await this.fetchByKeyword(keyword, page, this.limit);
      this.isCreating = false;
    } else {
      this.$router.push({ name: 'library-start' });
    }
  },

  computed: {
    ...mapGetters(['isLoading']),

    images() {
      return this.pageInfo ? this.pageInfo.data : [];
    },

    currentPage() {
      return parseInt(this.$route.query.page, 10) || 1;
    },
  },

  watch: {
    category(val) {
      if (val.info_sv) {
        this.translateInfo(val);
      }

      if (val.limitation_info_sv) {
        this.translateLimitationInfo(val);
      }
    },

    limit() {
      if (this.isCreating) return;

      this.$router.replace({
        path: this.$route.path,
        query: {
          limit: +localStorage.getItem('imagesPerPage'),
        },
      });
    },
  },

  methods: {
    fetchByKeyword(keywordId, page, limit) {
      Promise.all([
        axios.get(`${process.env.VUE_APP_BACKEND_URL}/api/keyword/${keywordId}`),
        axios.get(`${process.env.VUE_APP_BACKEND_URL}/api/image/keyword/${keywordId}?page=${page}&limit=${limit}`),
      ]).then((res) => {
        this.keyword = res[0].data.data;
        const searchResult = res[1].data;
        document.title = this.keyword.titleSv + " | " + i18n.t("tempus");

        this.pageInfo = searchResult;
      });
    },

    prevPage() {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: this.currentPage - 1,
        },
      });
    },

    nextPage() {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: this.currentPage + 1,
        },
      });
    },

    getPluralSingular() {
      return this.pageInfo?.total > 1 ? 'bilder' : 'bild';
    },
  },

  components: {
    FilterBar,
    Gallery,
    Breadcrumbs,
    MobileFilterBar,
  },
};
</script>

<template>
  <div class="flex flex-col md:flex-row">
    <div class="hidden md:block md:max-w-xs">
      <filter-bar
          v-if="keyword"
          :keyword="keyword"
          :limit.sync="limit"
      />
    </div>

    <div class="h-full w-full md:hidden">
      <MobileFilterBar
          v-if="keyword"
          :keyword="keyword"
          :limit.sync="limit"
      />
    </div>
    <div class="flex-1 bg-white">
      <div class="flex flex-col justify-between text-left p-10" v-if="keyword">
        <div class="hidden md:block">
          <breadcrumbs v-model="keyword" :light-mode="false" />
        </div>

          <div>
            <p class="text-2xl keyword-title">{{ keyword?.titleSv }}</p>
            <p class="text-sm pb-4 italic">
              {{ $t("databaseContains") }} {{ pageInfo?.total }} {{ $tc("image", pageInfo?.total) }} {{$t("withKeyword")}} <span>&#8220;{{keyword?.titleSv}}&#8221; </span>.
            </p>
          </div>
        </div>
<!--        <span class="text-sm info-text" v-html="keyword?.titleSv"></span>-->

      <gallery class="gallery-view w-full" v-model="images"></gallery>

      <div class="page-handle flex justify-center my-8" v-if="pageInfo">
        <button
          class="text-2xl"
          :disabled="pageInfo.current_page == 1"
          @click="prevPage()"
        >
          <i class="fas fa-chevron-left prev-page-icon"></i>
        </button>
        <div class="info text-2xl mx-4 inline-block">
          {{ pageInfo.current_page }} av {{ pageInfo.last_page }}
        </div>
        <button
          class="text-2xl"
          :disabled="pageInfo.current_page == pageInfo.last_page"
          @click="nextPage()"
        >
          <i class="fas fa-chevron-right next-page-icon"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.info-text {
   a {
    color: #4299e1 !important;
    text-decoration: underline !important;
  }
}

.keyword-title:first-letter {
  text-transform: uppercase;
}
</style>
